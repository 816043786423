@import 'variables';
@import 'mixins';
@import 'mq';

@import 'fonts';
// Required for Preview/Carousel
@import "swiper/scss";
@import "swiper/scss/navigation";
// @import "swiper/scss/pagination";
// @import "~swiper/components/thumbs/thumbs";
@import "swiper/scss/controller";

:root {
  --swiper-theme-color: #{$c-accent-2};
  --swiper-pagination-bullet-inactive-color: #ffffff;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --buki-c-default: #312D3A;
  --buki-c-default-2: #475060;
  --buki-c-default-alt: #ffffff;
  --buki-c-accent: #0174BE;
  --buki-c-accent_hover: #0d8cdb;
  --buki-c-accent_active: #11689e;
  --buki-c-accent-alt: #FDF6DF;
  --buki-c-accent-alt_hover: #ffffff;
  --buki-c-accent-alt_active: #cfc3a6;
  --buki-c-accent-2: #0C356A;
  --buki-c-accent-2_hover: #1d5194;
  --buki-c-accent-2_active: #0f2b4e;
  --buki-c-accent-3: #FFC436;
  --buki-c-accent-3_hover: #ffcd58;
  --buki-c-accent-3_active: #d3a12d;
  --buki-c-error: #D76A5C;
  --buki-c-discount: #250c0a;
  --buki-c-link: #EB6853;
  --buki-c-menu: #ffffff;
  --buki-c-input: var(--buki-c-default-2, #475060);
  --buki-c-placeholder: #CCCCCC;
  --buki-bgc-card: var(--buki-c-accent-alt, #FAF7D9);
  --buki-c-input-back: var(--buki-bgc-card, #FAF7D9);
  --buki-c-button-disabled: #BDBDBD;
  --buki-c-flashing: #F27078;
  // --buki-c-modal: var(--buki-c-default-alt, #ffffff);
  --buki-bgi-body: url(#{$src-images + "background.svg"});
  --buki-bgi-modal: url(#{$src-images + "background.jpg"});
  --buki-bgi-form: url(#{$src-images + "background.jpg"});
}

* {
  -webkit-tap-highlight-color: transparent;
}

*, *::before, *::after {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  // overflow: auto;
}

*::-webkit-scrollbar-thumb {
  border: 1px solid transparent;
  border-radius: 1px;
  background: darkgray;
  background-clip: padding-box;
}

html, body {
  // height: 100%; // ! affects on ios safari url bar (not hiding)
  min-width: 320px;
  overflow-x: hidden;
}

body {
  margin: 0;
  // background-image: #ccc;
  color: #333;
  font-family: $f-main;
  //padding-top: 105px;
}

p, h1, h2, h3, h4, h5 {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  max-height: 100%;
}

ol {
  list-style-type: decimal;

  ol {
    list-style-type: lower-alpha;
  }
}

/* ======== Angular material custom themes ======== */
.cdk-global-scrollblock {
  height: auto;
}

.cdk-overlay-dark-backdrop {
  // background: rgba(17, 30, 47, 0.8);
  background-image: $bgi-body;
}

.order-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.4);
}

.cdk-overlay-backdrop {
  &#{&}-showing {
    opacity: 0.8;
  }
}

// .cdk-global-overlay-wrapper {}

// .cdk-overlay-pane {}

.book-overlay-pane {
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;

  @include layout-bp(lt-md) {
    background-image: $bgi-modal;
    background-size: cover;
  }

  .mat-dialog-container {
    border-radius: 0;
    box-shadow: unset;
    background: unset;
    padding: 0;
    width: unset;
    height: unset;
    overflow: unset;
    color: inherit;

    @include layout-bp(lt-md) {
      width: 100%;
      height: 100%;
    }
  }
}

.order-overlay-pane {
  .mat-dialog-container {
    border-radius: 0;
    box-shadow: unset;
    background: unset;
    padding: 0;
    width: unset;
    height: unset;
    overflow: unset;
    color: inherit;
  }
}

.mat-calendar-body-selected {
  background-color: $c-accent;
}

.mat-datepicker-toggle-active {
  color: $c-accent;
}

.timepicker-overlay {
  z-index: 1001 !important;
}

.logo {
  display: block;
  @include character('logo.png', 179px, 100px);

  &#{&}_alt {
    @include image('logo_alt.png');
  }

  &#{&}_metro {
    @include image('logometro.png');
  }

  @include layout-bp(lt-xl) {
    @include size(161px, 90px);
  }

  @include layout-bp(lt-lg) {
    @include size(143px, 80px);
  }

  @include layout-bp(lt-md) {
    @include size(98px, 55px);
  }
}

lib-section-message-date {
  lib-select {
    .container {
      .container__head {
        border-bottom-color: $c-accent;
        color: $c-accent;
      }
    }

    .item {
      .item__arrow {
        &::after {
          background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 6.7998L11 0.399805H0L5.5 6.7998Z' fill='%230174BE'/%3E%3C/svg%3E%0A");
        }
      }
    }
  }

  // .text strong {
  //   color: #98D8AA !important;
  // }
}

lib-faq-item {
  .button.button_active {
    background-color: $c-accent-3 !important;

    &:hover {
      background-color: $c-accent-3_hover !important;
    }

    &:active {
      background-color: $c-accent-3_active !important;
    }
  }
}

lib-book-step-button-cart {
  > .container > .container__button {
    &::after {
      display: none;
    }

    > lib-button > .container {
      background-color: $c-accent-3;
      color: $c-default;

      &:hover {
        background-color: $c-accent-3_hover;
      }

      &:active {
        background-color: $c-accent-3_active;
      }

      &:disabled {
        background-color: $c-button-disabled;
        color: $c-default_alt;
      }

      &.container_icon-arrow-right::after {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 13L13 7M13 7L7 1M13 7H1' stroke='%23312D3A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }

      &:disabled.container_icon-arrow-right::after {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 13L13 7M13 7L7 1M13 7H1' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }
  }
}

// lib-book-step-button-back {
//   .back-btn {
//     color: #fff !important;

//     svg-icon.back-btn__icon [stroke] {
//       stroke: #fff !important;
//     }
//   }
// }

// lib-image-title {
//   .title {
//     & > .title__img.title__img_cart,
//     & > .title__img.title__img_faq,
//     & > .title__img.title__img_gift,
//     & > .title__img.title__img_reviews {
//       width: 152px;

//       @include layout-bp(lt-xl) {
//         width: 152px;
//       }

//       @include layout-bp(lt-lg) {
//         width: 152px;
//       }
//     }
//   }
// }

// lib-select-button {
//   .button.button_theme-alt .button__svg-icon path {
//     fill: $c-accent-2 !important;
//   }

//   .button.button_theme-alt:hover .button__svg-icon path {
//     fill: #fff !important;
//   }

//   .button.button_theme-alt:disabled .button__svg-icon path {
//     fill: #fff !important;
//   }
// }

lib-input-radio-cover-option {
  > .content > .content__tick {
    background-color: $c-accent-2;
  }
}

// lib-multiselect-option {
//   .container.container_selected {
//     border-color: $c-accent-2 !important;

//     svg-icon.container__icon [stroke] {
//       stroke: $c-accent-2 !important;
//     }
//   }
// }

// lib-book-step-button {
//   .container.container_active svg-icon.container__svg-icon {
//     background-color: $c-accent-2 !important;
//   }
// }

lib-navigation {
  .back-btn {
    svg-icon.back-btn__icon [stroke] {
      stroke: $c-accent-2 !important;
    }

    &:hover {
      svg-icon.back-btn__icon [stroke] {
        stroke: $c-accent-2_hover !important;
      }
    }

    &:active {
      svg-icon.back-btn__icon [stroke] {
        stroke: $c-accent-2_active !important;
      }
    }

    &:disabled {
      svg-icon.back-btn__icon [stroke] {
        stroke: $c-button-disabled !important;
      }
    }
  }
}

lib-accordeon {
  .header {
    &__tick {
      &.header__tick_active {
        border-color: $c-accent-3 !important;
        background-color: $c-accent-3 !important;
      }
    }

    &:hover .header__tick {
      border-color: $c-accent-3_hover !important;

      &.header__tick_active {
        background-color: $c-accent-3_hover !important;
      }
    }

    &:active .header__tick {
      border-color: $c-accent-3_active !important;

      &.header__tick_active {
        background-color: $c-accent-3_active !important;
      }
    }
  }
}

// lib-product-radio-card {
//   .container {
//     // &:hover {
//     //   outline-color: $c-accent-alt !important;
//     // }

//     &.container_active {
//       outline-color: $c-accent-2 !important;
//     }
//   }

//   .icon {
//     // [stroke] {
//     //   stroke: $c-accent-alt !important;
//     // }

//     &.icon_active {
//       [stroke] {
//         stroke: $c-accent-2 !important;
//       }
//     }
//   }
// }

// Yotpo
.yotpo .yotpo-review .yotpo-footer {
  display: none;
}

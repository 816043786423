
//ZenMaruGothic-Bold
@font-face {
  font-family: ZenMaruGothic;
  font-weight: 700;
  src: url("^assets/fonts/Anpanman/ZenMaruGothic-Bold.ttf") format("opentype");
  font-display: swap;
}

//ZenMaruGothic-Black
@font-face {
  font-family: ZenMaruGothic;
  font-weight: 900;
  src: url("^assets/fonts/Anpanman/ZenMaruGothic-Black.ttf") format("opentype");
  font-display: swap;
}

//MiniWakuwaku
@font-face {
  font-family: MiniWakuwaku;
  src: url("^assets/fonts/Anpanman/mini-wakuwaku.otf") format("opentype");
  font-display: swap;
}

//MiniWakuwakuKunchan
@font-face {
  font-family: MiniWakuwakuKunchan;
  src: url("^assets/fonts/Anpanman/mini-wakuwaku-kunchan-dev.otf") format("opentype");
  font-display: swap;
}

//HuiFont
@font-face {
  font-family: HuiFont;
  src: url("^assets/fonts/Anpanman/HuiFont.ttf") format("opentype");
  font-display: swap;
}

//APJapanesefont
@font-face {
  font-family: APJapanesefont;
  src: url("^assets/fonts/Anpanman/APJapanesefont.ttf") format("opentype");
  font-display: swap;
}
//KiwiMaru
@font-face {
  font-family: KiwiMaru;
  src: url("^assets/fonts/KiwiMaru/KiwiMaru-Regular.ttf") format("opentype");
  font-display: swap;
}
//CherryBombOne
@font-face {
  font-family: CherryBombOne;
  src: url("^assets/fonts/CherryBombOne/CherryBombOne-Regular.ttf") format("opentype");
  font-display: swap;
}
